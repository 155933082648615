<template>
  <div class="main-contents-wrap">
    <div class="page-search-wrap_sup">
      <div class="date-wrap" style="margin-right: 5px">
        <input type="text" class="dateSelector start flatpickr-input" placeholder="날짜를 선택하세요." value="2021-09-30T00:00:00" readonly="readonly" />
        <a class="input-button" title="toggle" data-toggle=""><i class="icon-calendar"></i></a>
        <input type="datetime-local" name="startDate" id="startDate" value="2021-09-30T00:00:00" />
        <span>~</span>
        <input type="text" class="dateSelector end flatpickr-input" placeholder="날짜를 선택하세요." value="2021-10-05T23:59:59" readonly="readonly" />
        <a class="input-button" title="toggle" data-toggle=""><i class="icon-calendar"></i></a>
        <input type="datetime-local" name="endDate" id="endDate" value="2021-10-05T23:59:59" />
      </div>
      <div class="date-selBtn-wrap">
        <input type="radio" id="date-selector1" name="date-selector" value="today" />
        <label for="date-selector1">오늘</label>
        <input type="radio" id="date-selector2" name="date-selector" value="aweek" />
        <label for="date-selector2">1주</label>
        <input type="radio" id="date-selector3" name="date-selector" value="weeks" />
        <label for="date-selector3">2주</label>
        <input type="radio" id="date-selector4" name="date-selector" value="amonth" />
        <label for="date-selector4">한달</label>
      </div>

      <select name="ordsrt2" id="ordsrt2">
        <option value="regDate DESC">일자 내림차순</option>
        <option value="regDate ASC">일자 오름차순</option>
      </select>

      <input type="text" placeholder="아이디 입력" id="memId" name="memId" value="" />
      <button class="btn-search" type="button" onclick="javascript:goSearch()">
        <i class="fas fa-search"></i>
      </button>
    </div>
    <div class="main-contents-wrapper">
      <table class="square-table">
        <thead>
          <tr>
            <th style="width: auto"><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.0">{{ $t('table.head.id') }}</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.1">닉네임</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.33">보유포인트</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.89">전환포인트</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.90">전환 후 보유머니</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.22">신청시간</span></th>
            <th><span class="table-sort up" onclick="sortChange(this)" data-i18n="table.24">{{ $t('searchArea.endDate') }}</span></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ldc73825')">ldc73825</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ldc73825')">손모가지걸어</a></span>
            </td>
            <td><span>9,216</span></td>
            <td><span>9,216</span></td>
            <td><span>630,196</span></td>
            <td><span>2021-10-05 15:14:52.0</span></td>
            <td><span>2021-10-05 15:14:52.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ldc73825')">ldc73825</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ldc73825')">손모가지걸어</a></span>
            </td>
            <td><span>72,992</span></td>
            <td><span>72,752</span></td>
            <td><span>393,176</span></td>
            <td><span>2021-10-05 14:37:42.0</span></td>
            <td><span>2021-10-05 14:37:42.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('alclssha0001')">alclssha0001</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('alclssha0001')">비포장풀악셀</a></span>
            </td>
            <td><span>47,542</span></td>
            <td><span>47,542</span></td>
            <td><span>1,077,814</span></td>
            <td><span>2021-10-05 11:54:24.0</span></td>
            <td><span>2021-10-05 11:54:24.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('alclssha0001')">alclssha0001</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('alclssha0001')">비포장풀악셀</a></span>
            </td>
            <td><span>45,970</span></td>
            <td><span>45,970</span></td>
            <td><span>546,072</span></td>
            <td><span>2021-10-05 10:53:05.0</span></td>
            <td><span>2021-10-05 10:53:05.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zosqjfk')">zosqjfk</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zosqjfk')">국영수</a></span>
            </td>
            <td><span>223,183</span></td>
            <td><span>223,183</span></td>
            <td><span>4,413,901</span></td>
            <td><span>2021-10-05 06:04:12.0</span></td>
            <td><span>2021-10-05 06:04:12.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>13,860</span></td>
            <td><span>13,590</span></td>
            <td><span>303,299</span></td>
            <td><span>2021-10-05 04:36:40.0</span></td>
            <td><span>2021-10-05 04:36:40.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>21,825</span></td>
            <td><span>21,825</span></td>
            <td><span>325,709</span></td>
            <td><span>2021-10-05 03:52:55.0</span></td>
            <td><span>2021-10-05 03:52:55.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>16,628</span></td>
            <td><span>16,628</span></td>
            <td><span>140,884</span></td>
            <td><span>2021-10-05 03:01:35.0</span></td>
            <td><span>2021-10-05 03:01:35.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>11,916</span></td>
            <td><span>11,916</span></td>
            <td><span>12,006</span></td>
            <td><span>2021-10-04 23:21:53.0</span></td>
            <td><span>2021-10-04 23:21:53.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>9,232</span></td>
            <td><span>9,216</span></td>
            <td><span>659,850</span></td>
            <td><span>2021-10-04 22:28:10.0</span></td>
            <td><span>2021-10-04 22:28:10.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>53,038</span></td>
            <td><span>53,038</span></td>
            <td><span>53,840</span></td>
            <td><span>2021-10-04 22:27:05.0</span></td>
            <td><span>2021-10-04 22:27:05.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('dong8888')">dong8888</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('dong8888')">겐팅</a></span>
            </td>
            <td><span>178,850</span></td>
            <td><span>178,850</span></td>
            <td><span>1,489,466</span></td>
            <td><span>2021-10-04 22:11:51.0</span></td>
            <td><span>2021-10-04 22:11:51.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>2,264</span></td>
            <td><span>2,264</span></td>
            <td><span>71,484</span></td>
            <td><span>2021-10-04 22:09:53.0</span></td>
            <td><span>2021-10-04 22:09:53.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>4,904</span></td>
            <td><span>4,904</span></td>
            <td><span>96,770</span></td>
            <td><span>2021-10-04 22:05:37.0</span></td>
            <td><span>2021-10-04 22:05:37.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>32,360</span></td>
            <td><span>32,360</span></td>
            <td><span>92,416</span></td>
            <td><span>2021-10-04 22:00:07.0</span></td>
            <td><span>2021-10-04 22:00:07.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('space1')">space1</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('space1')">zin</a></span>
            </td>
            <td><span>100,000</span></td>
            <td><span>100,000</span></td>
            <td><span>2,100,000</span></td>
            <td><span>2021-10-04 21:56:15.0</span></td>
            <td><span>2021-10-04 21:56:15.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ndy20000')">ndy20000</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('ndy20000')">대영이요</a></span>
            </td>
            <td><span>100,000</span></td>
            <td><span>100,000</span></td>
            <td><span>1,100,600</span></td>
            <td><span>2021-10-04 21:48:38.0</span></td>
            <td><span>2021-10-04 21:48:38.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('skyboydj')">skyboydj</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('skyboydj')">바나나우유</a></span>
            </td>
            <td><span>100,000</span></td>
            <td><span>100,000</span></td>
            <td><span>1,100,000</span></td>
            <td><span>2021-10-04 20:51:27.0</span></td>
            <td><span>2021-10-04 20:51:27.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">zu04</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('zu04')">비비</a></span>
            </td>
            <td><span>18,279</span></td>
            <td><span>18,279</span></td>
            <td><span>18,852</span></td>
            <td><span>2021-10-04 19:41:36.0</span></td>
            <td><span>2021-10-04 19:41:36.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>2,920</span></td>
            <td><span>2,920</span></td>
            <td><span>150,056</span></td>
            <td><span>2021-10-04 19:35:19.0</span></td>
            <td><span>2021-10-04 19:35:19.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>23,048</span></td>
            <td><span>23,048</span></td>
            <td><span>356,136</span></td>
            <td><span>2021-10-04 19:26:33.0</span></td>
            <td><span>2021-10-04 19:26:33.0</span></td>
          </tr>

          <tr>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">jjj0615</a></span>
            </td>
            <td>
              <span><a href="#" class="userDetailOpen" onclick="userDetailOpen('jjj0615')">네추럴</a></span>
            </td>
            <td><span>100,504</span></td>
            <td><span>100,504</span></td>
            <td><span>271,088</span></td>
            <td><span>2021-10-04 19:06:03.0</span></td>
            <td><span>2021-10-04 19:06:03.0</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import TableHead from '@/components/main/table/Head'
// import BtnTableInner from '@/components/ui/BtnTableInner'
// import SearchBar from '@/components/main/top/SearchBar.vue'
// import TableIndex from '@/components/main/table/index'


export default {
  name: 'gameList',
  components: {
    // SearchBar
    // TableIndex,
    // TableHead,
    // BtnTableInner
  },
  data: function () {
    return {
      tableName: 'Basic',

      headInfo: {
        fstColumn: true,
        dataList: ['아이디', '닉네임', '전화번호', '입금금액', '출금금액', '손익', '보유머니', '상태', '상위충판', '회원레벨', '게임레벨', '가입날짜']
      },
      innerBtnText: '베팅내역보기'
    }
  },
  methods: {
    openWindow () {
      window.open('/popup/userDetail', 'userDetail', 'top=100,left=150,scrollbars=no,resizable=no,width=1900,height=945,status=no')
    },
    hideOpen (target) {
      target = !target
    }
  }
}
</script>

<style scoped>
</style>
